<template>
  <div>
    <ed-table
      :key="$root.$session.versao"
      strModel="Documento/Revisao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :loading="loading"
      :filter="formFiltros"
      @register="onRegister"
      @edit="onEdit"
      @search="getRevisao"
      :config="{ registerName: 'Adicionar novo PCMSO' }"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  mounted() {
    this.getRevisao();
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Empresa",
          sortable: true,
          value: "intCadastroGeralEmpresaId",
        },
        {
          text: "Unidade de Trabalho",
          sortable: true,
          value: "intCadastroGeralUnidadeId",
        },
        {
          text: "Revisões do PCMSO",
          sortable: true,
          collapse: 10,
          collapseLabel: "Visualizar...",
          value: "revisoes",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros: {
        intTipoCadastroGeralId: 58,
        intTipoDocumentoId: 279,
        intTipoRevisaoId: 285,
      },
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "pcmso.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "pcmso.edit",
        params: {
          intCadastroGeralEmpresaId: data.cadastro_geral.intId,
          intCadastroGeralUnidadeId: data.intId,
        },
      });
    },

    getRevisao() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Documento/Revisao", this.formFiltros)
        .then((objResult) => {
          this.loading = false;

          if (objResult.status == 200 && objResult.result) {
            this.objPagination = objResult.result;

            let arrayRows = [];

            objResult.result.data.forEach((objUnidade) => {
              let revisoes = '<table class="table">';

              revisoes += "<tr>";
              revisoes += "<th>Tipo</th>";
              revisoes += "<th>Revisão</th>";
              revisoes += "<th>Data</th>";
              revisoes += "<th>Status</th>";
              revisoes += "</tr>";
              objUnidade.revisoes.forEach((objRevisao) => {
                // revisoes += '<tr>'
                //   revisoes += '<td colspan="4" style="background:#cccccc">'+objRevisaoDocumento.tipo_documento.strNome+'</td>'
                // revisoes += '</tr>'

                objRevisao.documentos.forEach((objRevisaoDocumento) => {
                  revisoes += "<tr>";
                  revisoes +=
                    '<td style="color:' +
                    (objRevisaoDocumento.tipo_status
                      ? objRevisaoDocumento.tipo_status.strCor
                      : "") +
                    '">' +
                    objRevisaoDocumento.tipo_documento.strNome +
                    "</td>";
                  revisoes +=
                    '<td style="color:' +
                    (objRevisaoDocumento.tipo_status
                      ? objRevisaoDocumento.tipo_status.strCor
                      : "") +
                    '">' +
                    objRevisaoDocumento.strNumero +
                    "</td>";
                  revisoes +=
                    '<td style="color:' +
                    (objRevisaoDocumento.tipo_status
                      ? objRevisaoDocumento.tipo_status.strCor
                      : "") +
                    '">' +
                    this.$utilidade.toDate(
                      objRevisaoDocumento.strDataDocumento,
                      true
                    ) +
                    "</td>";
                  revisoes +=
                    '<td style="color:' +
                    (objRevisaoDocumento.tipo_status
                      ? objRevisaoDocumento.tipo_status.strCor
                      : "") +
                    '">' +
                    (objRevisaoDocumento.tipo_status
                      ? objRevisaoDocumento.tipo_status.strNome
                      : "---") +
                    "</td>";
                  revisoes += "</tr>";
                });
              });

              revisoes += "</table>";

              let item = {
                intId: objUnidade.intId,
                intCadastroGeralEmpresaId: objUnidade.cadastro_geral.strNome,
                intCadastroGeralUnidadeId: objUnidade.strNome,
                revisoes: revisoes,
                _item: objUnidade,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          } else {
            this.$root.$message.warning("Dados inválidos");
          }
        });
    },
  },
};
</script>
